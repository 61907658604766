<template>
  <div>
    <h4 class="mb-4">Рассылка</h4>
    <div class="widget">
      <template v-if="channels && channels.length">
        <form class="row g-1">
          <b-form-group
            id="input-group-1"
            label="Выберите каналы связи:"
            class="col-12"
            label-for="notificationChannels"
          >
            <vue-multiselect
              required
              class="custom__multiselect"
              :options="channels"
              :multiple="true"
              :state="channelsValidation"
              :close-on-select="false"
              :clear-on-select="false"
              selectedLabel="Выбран"
              selectLabel="нажмите чтобы выбрать"
              deselectLabel="нажмите чтобы убрать"
              placeholder="Выберите канал связи"
              :tagable="true"
              tagPosition="bottom"
              label="text"
              v-model="form.selected_channels"
              track-by="value"
              id="notificationChannels"
            >
            </vue-multiselect>

            <p class="invalid-feedback-custom pt-2" v-if="!channelsValidation">Необходимо выбрать хотя бы один канал связи</p>
          </b-form-group>

          <b-form-group
            id="input-group-1"
            label="Заголовок:"
            class="col-12"
            label-for="notificationTitle"
          >
            <b-form-input
              required
              id="notificationTitle"
              v-model="form.title"
              type="text"
              placeholder="Заголовок"
              :state="titleValidation"
            ></b-form-input>

            <b-form-invalid-feedback class="pt-2" v-if="!titleValidation">
              Поле обязательно для заполнения
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="input-group-1"
            label="Сообщение:"
            class="col-12"
            label-for="notificationMessage"
          >
            <b-form-textarea
              required
              id='notificationMessage'
              v-model='form.message'
              type='text'
              :state="messageValidation"
              placeholder='Сообщение'
            ></b-form-textarea>
            <b-form-invalid-feedback class="pt-2" v-if="!messageValidation">
              Поле обязательно для заполнения
            </b-form-invalid-feedback>
          </b-form-group>


          <div class="col-12 text-right">
            <b-button
              type="submit"
              class="mr-2"
              variant="success"
              @click.prevent="sendNotification"
            >
              <b-spinner v-if="isAccepting" style="width: 1.4rem; height: 1.4rem;" label="Sending"></b-spinner>
              <span :class="{ captionPadding: isAccepting }">Отправить уведомление</span>
            </b-button>
          </div>

        </form>

      </template>
      <template v-else>
        <div class="d-flex align-center justify-content-center mt-2">
          <p class="text-danger">Не удалось загрузить список каналов связи, обратитесь в поддержку!</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';

export default {
  name: "SendingNotificationComponent",
  props: [],
  components: {VueMultiselect},
  data() {
    return {
      isAccepting: false,
      channels: [],
      form: {
        selected_channels: [],
        title: '',
        message: ''
      }
    }
  },
  computed: {
    titleValidation() {
      return (!!this.form.title && this.form.title.length >= 2);
    },
    messageValidation() {
      return (!!this.form.message && this.form.message.length >= 1);
    },
    channelsValidation() {
      console.log(!!this.form.selected_channels.length)
      return (!!this.form.selected_channels.length);
    },
  },
  methods: {
    async fetchNotificationChannels() {
      try {
        const {data} = await this.$axios.get('crm/notifications/channels');
        if (data && data.success) {
          this.channels = data.data.channels;
        }
      } catch (e) {
        let errMsg = `Ошибка получения типов уведомлений`;
        if (e.response && e.response.data.error) {
          errMsg = e.response.data.error;
        } else if (e.response && e.response.data.message) {
          errMsg = e.response.data.message;
        }
        this.showErrorNotify(errMsg)
      }
    },
    async sendNotification() {
      try {
        if (!this.titleValidation || !this.messageValidation || !this.channelsValidation) {
          this.showErrorNotify('Проверьте правильность заполнения полей!')
          return
        }
        this.isAccepting = true
        const {data} = await this.$axios.post('crm/notifications/send', {
          selected_channels: this.form.selected_channels.map((value) => value.value),
          title: this.form.title,
          message: this.form.message,
        });
        if (data && data.success) {
          this.isAccepting = false
          this.showNotify('Уведомления отправлены и скоро будут доставлены всем клиентам!')
        }
      } catch (e) {
        this.isAccepting = false
        let errMsg = `Ошибка отправки уведомлений`;
        if (e.response && e.response.data.error) {
          errMsg = e.response.data.error;
        } else if (e.response && e.response.data.message) {
          errMsg = e.response.data.message;
        }
        this.showErrorNotify(errMsg)
      }
    },
    showNotify(text) {
      this.$toasted.success(text, {
        position: 'bottom-right',
        action: {
          text: 'Закрыть',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
    showErrorNotify(text) {
      this.$toasted.error(text, {
        position: 'bottom-right',
        action: {
          text: 'Закрыть',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
  },
  created() {
    this.fetchNotificationChannels()
  }
}
</script>

<style scoped lang="scss">
  .invalid-feedback-custom {
    margin-bottom: 0;
    width: 100%;
    font-size: 80%;
    color: #FD5F00;
  }
</style>