<template>
    <div>
        <h4 class="mb-4">Настройки CRM</h4>
        <b-alert show variant="warning"><b>Не меняйте настройки, если не уверены, как они работают!</b></b-alert>
        <div class="widget">
            <b-form class="row g-1" @submit="submitForm">
                <b-form-group
                        v-for="item in getSettingsList"
                        :id="item.name"
                        :label="item.title"
                        class="col-6"
                        :label-for="item.name"
                        :key="item.id"
                >
                    <b-form-input
                            :id="item.name"
                            v-model="form[item.name]"
                            :value="form[item.name]"
                            type="text"
                            placeholder=""
                            required
                    ></b-form-input>
                </b-form-group>
                <b-form-group class="col-12 text-right">
                    <b-button type="submit" class="mr-2" variant="success">Сохранить</b-button>
                </b-form-group>
            </b-form>
        </div>

        <h4 class="mb-4">Настройки ответов чат-бота</h4>

        <div class="widget">
          <div class="row">
            <div class="col-12">
              <b-button class="mr-2 mb-3" variant="success" @click.prevent="triggerAddingNew()">
                <span class="icon"><i class="fi flaticon-add"></i></span> Добавить новый
              </b-button>
            </div>

            <div class="col-lg-12 mb-5" v-if="isAddingNew">
              <div class="widget mb-3" style="background: #e0f9f3;">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-2">
                      Вопрос:
                      <b-form-input
                          v-model="newChatBotItem.question"
                          style="border: 1px solid #c1ccd3 !important;"
                          type="text"
                          placeholder="Вопрос"
                      ></b-form-input>
                    </div>
                    <div>
                      <b-form-textarea
                          v-model="newChatBotItem.answer"
                          rows="7"
                          placeholder="Ответ"
                      ></b-form-textarea>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div style="display: flex; flex-direction: column; height: 100%; justify-content: space-between;">
                      <div>
                        Набор правил
                        <b-form-textarea
                            v-model="newChatBotItem.keywords"
                            rows="6"
                            placeholder="Набор правил"
                            style="margin-bottom: 10px;"
                        ></b-form-textarea>
                      </div>
                      <div style="display: flex; justify-content: space-between;">
                        <div :id="`error-label-id-0`" class="message-label" style="color: red;"></div>
                        <div :id="`success-label-id-0`" class="message-label" style="color: green;"></div>

                        <div>
                          <div :id="`spinner-id-0`" class="spinner"></div>

                          <b-button class="mr-2" variant="primary" @click.prevent="createChatBotItem()">
                            <span class="icon"><i class="fi flaticon-save"></i></span> Сохранить
                          </b-button>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="col-lg-12">


              <template>
                <div class="widget mb-3" v-for="(item) in chatBotAnswers" :key="item.id">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-2">
                        Вопрос:
                        <b-form-input
                            v-model="item.question"
                            style="border: 1px solid #c1ccd3 !important;"
                            type="text"
                            placeholder="Вопрос"
                        ></b-form-input>
                      </div>
                      <div>
                        <b-form-textarea
                            v-model="item.answer"
                            rows="7"
                            placeholder="Ответ"
                        ></b-form-textarea>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div style="display: flex; flex-direction: column; height: 100%; justify-content: space-between;">
                        <div>
                          Набор правил
                          <b-form-textarea
                              v-model="item.keywords"
                              rows="6"
                              placeholder="Набор правил"
                              style="margin-bottom: 10px;"
                          ></b-form-textarea>
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                          <div :id="`error-label-id-${item.id}`" class="message-label" style="color: red;"></div>
                          <div :id="`success-label-id-${item.id}`" class="message-label" style="color: green;"></div>

                          <div>
                            <div :id="`spinner-id-${item.id}`" class="spinner"></div>

                            <b-button class="mr-2" variant="primary" @click.prevent="saveChatBotItem(item)">
                              <span class="icon"><i class="fi flaticon-save"></i></span> Сохранить
                            </b-button>

                            <b-button class="mr-2" variant="danger" @click.prevent="deleteChatBotItem(item)">
                              <span class="icon"><i class="fi flaticon-minus"></i></span> Удалить
                            </b-button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>


            </div>
          </div>


        </div>

        <h4 class="mb-4">Дополнительно</h4>

        <div class="widget" v-if="isReady">
          <div class="row">
            <div class="col-12">
              <div style="display: flex;">
                <b-button class="mr-2 mt-3 mb-3" :variant="isInformerEnabled ? 'success' : 'default'" @click.prevent="toogleInformer()">
                  {{ isInformerEnabled ? `Выключить информирование о часах работы в чат-боте` : `Включить информирование о часах работы в чат-боте` }}
                </b-button>

                <div id="spinner-id-0" class="spinner" :style="isProcessing ? { display: 'inline-block' } : { display: 'none' }"></div>
              </div>
            </div>

          </div>
        </div>

      <sending-notification-component v-if="isAdmin"/>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import DeleteModal from "../../components/Modal/DeleteModal";
    import Multiselect from "vue-multiselect";
    import SendingNotificationComponent from "@/components/Notifications/SendingNotificationComponent.vue";

    export default {
        name: 'Settings',
      components: {SendingNotificationComponent, Multiselect},
        data() {
            return {
                form: {},

                chatBotAnswers: [],
                isAddingNew: false,
                newChatBotItem: {},
                isInformerEnabled: false,
                isProcessing: false,
                isReady: false,
            }
        },
        computed: {
            ...mapGetters({
                getSettingsList: 'settings/getSettingsList',
                getRole: 'user/getRole',
            }),
          isAdmin() {
            return this.getRole === 'admin';
          },
        },
        methods: {
            ...mapActions({
                fetchSettingsList: 'settings/fetchSettingsList',
                updateSettings: 'settings/updateSettings',
            }),
            async submitForm(e) {
                e.preventDefault();
                const formData = { ...this.form };
                await this.updateSettings(formData);
                await this.fetchSettingsList();
            },
            buildForm() {
                for (const item of this.getSettingsList) {
                    this.form[item.name] = item.value;
                }
            },
            async fetchChatBotAnswers() {
              this.chatBotAnswers = (await this.$axios.get('/api/chat-bot-answers')).data

            },
            async saveChatBotItem(item) {
              this.showSpinner(item)
              try {
                await this.$axios.post(`/api/chat-bot-answers/${item.id}`, item)
              } catch (e) {
                if (e.response.status === 400) {
                  this.hideSpinner(item)
                  this.showResultMessage(item, e.response.data[0][Object.keys(e.response.data[0])[0]][0], 'error')
                  return
                }
              }

              this.showResultMessage(item, 'Сохранено', 'success')

              this.hideSpinner(item)
            },
            async deleteChatBotItem(item) {
              this.$modal.show(
                  DeleteModal,
                  {
                    recordId: item.id,
                    deleteHandler: async (id) => {
                      this.showSpinner(item)
                      await this.$axios.delete(`/api/chat-bot-answers/${item.id}`)
                      let indexToDelete = this.chatBotAnswers.findIndex(i => i.id === item.id)
                      this.chatBotAnswers.splice(indexToDelete, 1)
                    }
                  }, { name: 'DeleteModal' }
              )
            },

            triggerAddingNew() {
              this.isAddingNew = !this.isAddingNew
            },

            async createChatBotItem() {
              document.querySelector(`#spinner-id-0`).style.display = 'inline-block'
              try {
                await this.$axios.post(`/api/chat-bot-answers`, this.newChatBotItem)
              } catch (e) {
                if (e.response.status === 400) {
                  document.querySelector(`#spinner-id-0`).style.display = 'none'
                  document.querySelector(`#error-label-id-0`).style.display = 'block'
                  document.querySelector(`#error-label-id-0`).textContent = e.response.data[0][Object.keys(e.response.data[0])[0]][0]
                  return
                }
              }

              this.newChatBotItem = {}
              await this.fetchChatBotAnswers()
              this.triggerAddingNew()
            },

            showResultMessage(item, text, type = 'success') {
              if (type === 'success') {
                document.querySelector(`#success-label-id-${item.id}`).style.display = 'block'
                document.querySelector(`#error-label-id-${item.id}`).style.display = 'none'
                document.querySelector(`#success-label-id-${item.id}`).textContent = text
              } else {
                document.querySelector(`#success-label-id-${item.id}`).style.display = 'none'
                document.querySelector(`#error-label-id-${item.id}`).style.display = 'block'
                document.querySelector(`#error-label-id-${item.id}`).textContent = text
              }

            },

            showSpinner(item) {
              this.showResultMessage(item, '')
              document.querySelector(`#spinner-id-${item.id}`).style.display = 'inline-block'
            },
            hideSpinner(item) {
              document.querySelector(`#spinner-id-${item.id}`).style.display = 'none'
            },
            async toogleInformer() {
              if (this.isProcessing) {
                  return false
              }

              this.isProcessing = true;

              let response = null;
              try {
                response = await this.$axios.post(`/api/chat-bot-toggle-informer`)
              } catch (e) {
                console.log(e)
              }

              if (response && response.status === 200) {
                this.isInformerEnabled = response.data === 1 ? true : false;
              }

              this.isProcessing = false;
            }
        },
        watch: {
            getSettingsList: function (data) {
                for (const item of data) {
                    this.form[item.name] = item.value;
                }
            }
        },
        async created() {
            //await this.fetchSettingsList();
            await Promise.all([this.fetchSettingsList(), this.fetchChatBotAnswers()])
            this.buildForm();
            let response = await this.$axios.get(`/api/chat-bot-get-informer`);
            if (response.data === 1) {
              this.isInformerEnabled = true;
            }
            this.isReady = true;
        }
    }
</script>

<style scoped>
  @keyframes spinner {
    to {transform: rotate(360deg);}
  }

  .spinner {
    position: relative;
    margin-right: 30px;
    display: none;
    top: -3px;
  }

  .spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #000;
    animation: spinner .6s linear infinite;
  }
</style>
